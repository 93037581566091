<template>
  <div id="modal">
    <div class="modal-bg" @click.exact="fecharModalBg">
      <div v-if="configModal.btnFechar" class="modal-fechar" :class="{ light: configModal.btnFecharClaro }" @click="fecharModal"></div>
      <div class="modal-conteudo">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['configModal'],
  name: 'BoxModal',
  computed: {
    revendasSelecionada() {
      return this.$store.state.dadosPesquisa.revendasSelecionadas && this.$store.state.dadosPesquisa.revendasSelecionadas.length > 0;
    },
  },
  methods: {
    fecharModal() {
      if (this.configModal.modalRevenda && !this.revendasSelecionada) {
        return false;
      }
      !this.configModal.btnFechar ? false : this.$store.commit('SET_MODAL', '');
    },
    fecharModalBg($event) {
      if (this.configModal.modalRevenda && !this.revendasSelecionada) {
        return false;
      }
      if ($event.target.className == 'modal-bg') {
        this.fecharModal();
      }
    },
  },
  watch: {
    revendasSelecionada(val1, val2) {
      if (this.configModal.modalRevenda && val1 && !val2) {
        this.$store.commit('SET_MODAL', '');
      }
    },
  },
};
</script>

<style lang="scss">
#modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background: rgba($roxo1, 0.5);
  padding: 50px 25px 70px 25px;
  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;
    @media (max-width: 800px) {
      height: 100%;
    }
    &:before {
      content: '';
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }
    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
      }
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: '';
        border-radius: 20px;
        background: #5303ad;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &.light:before,
      &.light:after {
        background: #ffd201;
      }
    }
    .modal-conteudo {
      position: relative;
      width: 100%;
      @media (max-width: 800px) {
        height: 100%;
      }
      > div {
        box-sizing: border-box;
        @media (max-width: 800px) {
          height: 100%;
        }
      }
    }
  }
}
</style>
